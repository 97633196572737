<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-select
            codeGroupCd="PSR_EQUIPMENT_STATUS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="psrEquipmentStatusCd"
            label="설비상태"
            v-model="searchParam.psrEquipmentStatusCd"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <!-- 점검부서 -->
          <c-dept 
            type="search" 
            label="점검부서" 
            name="checkDeptCd" 
            :plantCd="searchParam.plantCd" 
            v-model="searchParam.checkDeptCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-select
            stepperGrpCd="PSR_CHECK_STATUS_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="psrCheckStatusCd"
            label="진행단계"
            v-model="searchParam.psrCheckStatusCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <!-- <div class="card-header q-table-header">
        가동전 점검
      </div> -->
      <div class="card-body qtable-card-body">
        <q-table
          grid
          title=""
          class="qtable-card"
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorStatus(props.row.psrCheckStatusCd)]">
                    {{ props.row.psrCheckStatusName }}
                  </div>
                  <div :class="['text-grid-etc', getColorStatus2(props.row.psrEquipmentStatusCd)]">
                    {{ props.row.psrEquipmentStatusName }}
                  </div>
                  <div class="txt-box-grid text-gray-box">
                    {{ props.row.checkDeptName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.checkName }}</div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'pre-startup-review',
  data() {
    return {
      searchParam: {
        plantCd: null,
        checkDeptCd: null,
        psrEquipmentStatusCd: null,
        psrCheckStatusCd: null,
      },
      grid: {
        columns: [
          // {
          //   name: 'plantName',
          //   field: 'plantName',
          //   label: '사업장',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
          {
            name: 'checkName',
            field: 'checkName',
            label: '점검명',
            align: 'left',
            style: 'width:70%',
            sortable: true,
            type: 'link',
          },
          // {
          //   name: 'psrEquipmentStatusName',
          //   field: 'psrEquipmentStatusName',
          //   label: '설비상태',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true
          // },
          {
            name: 'checkDeptName',
            field: 'checkDeptName',
            label: '점검부서',
            align: 'center',
            style: 'width:30%',
            sortable: true
          },
          // {
          //   name: 'psrCheckStatusName',
          //   field: 'psrCheckStatusName',
          //   label: '진행단계',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true
          // },
          // {
          //   name: 'sopMocId',
          //   field: 'sopMocId',
          //   label: 'MOC 번호',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.psr.check.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(evt, row) {
      this.insertPop(row);
    },
    insertPop(row) {
      this.popupOptions.title = '가동전점검 상세'; // 가동전점검 상세
      this.popupOptions.param = {
        sopPrestartupCheckId: row ? row.sopPrestartupCheckId : '',
      };
      this.popupOptions.target = () => import(`${'./preStartupReviewDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'PCSC000001': // 점검중
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'PCSC000005': // 점검완료
          cls = 'txt-box-grid text-positive-box';
          break;
      }
      return cls;
    },
    getColorStatus2(cd) {
      var cls = '';
      switch(cd) {
        case 'PESC000001': // 변경
          cls = 'txt-box-grid text-accent-box';
          break;
        case 'PESC000005': // 설치
          cls = 'txt-box-grid text-orange-box';
          break;
        case 'PESC000010': // 정비·보수
          cls = 'txt-box-grid text-red-box';
          break;
      }
      return cls;
    },
  }
};
</script>
